export const OrderStatus: { [key: string] : any }  = {
    waiting: "Непотвърдена",
    waiting_payment : "Непотвърдена",
    completed : "Завършена",
    cancelled : "Отказана",
    confirmed : "Потвърдена",
    in_delivery : "В процес",
    delivered  : "Доставена ",
    returned: "Върнати",
    printed: "Подготвена",
    requested: "Заявена към куриер"  
}