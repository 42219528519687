import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { TableProduct } from 'src/models/DTO/products/tableProduct';
import { TableService } from 'src/models/DTO/services/tableService';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { ProductService } from 'src/services/requestServices/products/product.service';
import { AutoMapper } from 'src/infrastructure/mappers/automapper';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { Convertor } from 'src/infrastructure/convertorHelpers/convertor';
import { DomSanitizer } from '@angular/platform-browser';
import { PricingService } from 'src/services/requestServices/pricing/pricing.service';
import { NewProduct } from 'src/models/requestModels/product/newProductRequestModel';
import { ToastrService } from 'ngx-toastr';
import { Successes } from 'src/config/labels/successes';
import { ServiceService } from 'src/services/requestServices/services/service.service';
import { TableItem } from 'src/models/DTO/base/tableItem';
import { ItemService } from 'src/services/requestServices/item/item.service';
import { LoggedUser } from 'src/models/DTO/user/LoggedUser';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { ReservationIntevals } from 'src/config/labels/reservationInterval';
import { SearchService as Search } from 'src/services/requestServices/search/search.service';
import { VariationsService } from 'src/services/requestServices/variations/variations.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { ProductsService } from 'src/services/requestServices/V2/products/products.service';
import { SearchService } from 'src/services/requestServices/V2/search/search.service';
import { TableProduct as TProduct } from 'src/models/DTO/V2/product/TableProduct';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { Currencies } from 'src/config/labels/currencies';
import { ProductStatuses } from 'src/config/labels/productStatuses';
import { ProductCollection } from 'src/models/DTO/V2/product/ProductCollection';
import { TableImageCollection } from 'src/models/DTO/V2/image/TableImageCollection';
import { SkuoptionService } from 'src/services/requestServices/V2/skuOption/skuoption.service';
import { TableSkuOptionCollection } from 'src/models/DTO/V2/skuOptions/TableSkuOptionCollection';
import { Option } from 'src/models/DTO/V2/skuOptions/Option';
import { ProductCollectionItem } from 'src/models/DTO/V2/product/ProductCollectionItem';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { LoadingstateService } from 'src/services/stateServices/loading/loadingstate.service';
import { SharedLabels } from 'src/config/labels/sharedLabels';
import { fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Labels } from 'src/config/labels/labels';

declare var bootstrap: any;



@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProductsComponent extends BaseDashboardComponent implements OnInit {
  public productSelection: any;
  public displayedColumnsForProductTable: string[];
  public productsDataSource: TProduct[] = [];
  public numberPerPageValue : number = 10;
  public page: number = 0;
  public  productImages: TableImageCollection = {
    images: []
  };
  public skuOptions: TableSkuOptionCollection = {
    tableSkus: []
  };
  public state: string = 'active';
  clicked = true;

  productDataSource: MatTableDataSource<TableProduct>;

  productArchiveSelection: any;
  productArchiveDataSource: MatTableDataSource<TableProduct>;

  serviceSelection: any;
  displayedColumnsForServiceTable: string[];
  serviceDataSource: MatTableDataSource<TableService>;

  serviceArchiveSelection: any;
  serviceArchiveDataSource: MatTableDataSource<TableService>;

  expandedElement: TableProduct | null;

  @ViewChild(MatSort) sortProduct: MatSort;
  @ViewChild('servicesSort') sortService: MatSort;
  @ViewChild('productArchiveSort') sortArchiveProduct: MatSort;
  @ViewChild('servicesArchiveSort') sortArchiveService: MatSort;
  @ViewChild('productTable') set productTable(content: MatTable<any>) {
    if(content) { // initially setter gets called with undefined
      this.loadTableTooltips();
    }
  }
  // @ViewChild('search') set searchElement(content: ElementRef) {
  //   if (content) {
  //     fromEvent(content.nativeElement,'input')
  //     .pipe(
  //         debounceTime(1000),
  //     )
  //     .subscribe((event: any) => this.search(event));
  //   }
  // };

  private _pageNumber = 1;
  private _searchString = "";
  private _searchType = "name.contains";
  private _initialNumberOfLoadedPages : number = 3;
  private _maxPage : number = 100;
  private _scrolling = false;
  private _active = 0;
  private _draft = 0;
  private _archived = 0;




  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected partnersService: PartnersService,
    protected productService: ProductService,
    protected usersService: UsersService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    private servicesService: ServiceService,
    private itemService: ItemService,
    private pricingService: PricingService,
    private toastrService: ToastrService,
    private searchService: Search,
    private variationsService: VariationsService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    private productsService: ProductsService,
    protected imageService: ImageService,
    private searchingService: SearchService,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService,
    private loadingState: LoadingstateService

  ) {
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentsService);
    this.pageName = "products";
    const initialSelection = [];
    const allowMultiSelect = true;
    this.loadTotals();

    this.productSelection = new SelectionModel<TableProduct>(allowMultiSelect, initialSelection);
    this.productArchiveSelection = new SelectionModel<TableProduct>(allowMultiSelect, initialSelection);

    this.displayedColumnsForProductTable = ['image', 'name', 'SKU', 'quantity', 'ordered', 'meduimPrice', 'date', 'state', 'edit', 'change', 'status'];

    this.loadData();
    this.loadingState.loading$.subscribe(result => {
      this.isLoading = result;
    });
    this.numberPerPageValue = Number.parseInt(this.localStorageService.getLocalStorageItem(Labels.lsNumberOfProductsPerPage)) || 10;
    this.initialLoad();
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.clicked = false;
  }

  get lastPage() {
    return this._maxPage;
  }

  get totalProducts() {
    return this._active + this._archived + this._draft;
  }

  get totalActive() {
    return " (" + this._active + ")";
  }

  get totalDrafts() {
    return " (" + this._draft + ")";
  }

  get totalArchived() {
    return " (" + this._archived + ")";
  }

  get searchString() {
    return this._searchString;
  }

  get areLogisticsFilled() {
    if (!this.loggedPartner?.data?.carrier?.data[0] ||
        !this.loggedPartner?.data?.organisations[0].delivery_to_address_price ||
        !this.loggedPartner?.data?.organisations[0].delivery_to_courier_office_price ||
        !this.loggedPartner?.data?.organisations[0].free_delivery_after_order_amount) {
          return false;
        }
        return true;
  }

  // V2
  get numberPerPage() {
    return this.numberPerPageValue + " " + this.dashboardLables(this.getPageName()).productsLabel;
  }

  get pageNumber() : number{
    return +this._pageNumber;
  }


  get priceConvertor() {
    return Convertor.convertPrice.splitPriceToFloat
  }



  private loadTotals() {
    let states = ['active', 'draft', 'archived'];
    states.forEach(state => {
      this.productsService.getProducts(this.numberPerPageValue, this.page, state).subscribe((productsData) => {
        let key = '_' + state;
        this[key] = productsData.meta.total;
      });
    })
  }

  private async loadData() {
    await this.onDirectNavigation();
  }

  private getProducts() {
    if (this._searchString) {
      this.loadSearchedProductsForTable();
    }
    else {
      this.loadProductsForTable();
    }
  }

  private initialLoad() {
    this.productsDataSource = [];
    this.page = 1;
    this.getProducts();
    // This is only if the initial state is 'all'
    // for(let i = 0; i < this._initialNumberOfLoadedPages; i++) {
    //   this.page++;
    //   this.getProducts();
    // }
  }

  private loadProductsForTable() {
    this.productsService.getProducts(this.numberPerPageValue, this.page, this.state).subscribe((productsData) => {
      this.fillProductsTable(productsData);
    });
  }

  private loadSearchedProductsForTable() {
    this.searchingService.searchProducts(this.numberPerPageValue, this.page, this._searchString, this.state).subscribe(productsData => {
      this.fillProductsTable(productsData)
    })
  }

  private fillProductsTable(productsData: ProductCollection): void {
    this._maxPage = productsData.meta.last_page;
    productsData.data.forEach(product => {
      if (!this.productsDataSource.find((currentProduct => currentProduct.product.uuid == product.uuid))) {
        const newTableProductCollectionItem: TProduct = {
          product: {
            uuid: product.uuid,
            name: product.name,
            state: product.state,
            pricing: [],
            organisation: {
                uuid: product.organisation.uuid,
                native_name: product.organisation.native_name,
                name_in_latin: product.organisation.name_in_latin
            },
            category: {
                uuid: product.category.uuid,
                name: product.category.name
            },
            brand: {
                uuid: product.brand.uuid,
                name: product.brand.name
            },
            skus: [],
            total_available_quantity: product.total_available_quantity,
            total_reserved_quantity: product.total_reserved_quantity,
            created_at: product.created_at,
            updated_at: product.updated_at,
            is_in_group: product.is_in_group
          }
        };
        product.pricing.forEach(price => {
          newTableProductCollectionItem.product.pricing.push({
            uuid: price.uuid,
            quantity_from: price.quantity_from,
            quantity_to: price.quantity_to,
            price_per_piece: price.price_per_piece,
            diff_in_percent_from_single_quantity: price.diff_in_percent_from_single_quantity
          });
        });
        product.skus.forEach(sku => {
          newTableProductCollectionItem.product.skus.push({
            uuid: sku.uuid,
            sku: sku.sku,
            available_quantity: sku.available_quantity,
            reserved_quantity: sku.reserved_quantity,
            options: sku.options
          });
        });
        this.productsDataSource  = [...this.productsDataSource, newTableProductCollectionItem]
      }
      this._scrolling = false;
      });
    this.getImagesForItems(this.productsDataSource);
  }


  private getImagesForItems(productCollection: TProduct[]) {
    this.productImages.images = [];
    productCollection.forEach(product => {
      this.imageService.getImagesPerProduct("PRODUCT", product.product.uuid).subscribe(images => {
        this.productImages.images.push({
          itemUUID:  product.product.uuid,
          imageUrl: images.data[0]?.original.url
        })
      });
      product.product.skus.forEach(sku => {
        this.imageService.getImagesPerProduct("SKU", sku.uuid).subscribe(images => {
          this.productImages.images.push({
            itemUUID: sku.uuid,
            imageUrl: images.data[0]?.original.url
          })
        });
      })
    })
  }

  public clearSearchString() {
    this._searchString = '';
    this.productsDataSource = [];
    this.initialLoad();
  }

  public loadTableTooltips() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  public deleteProduct(element: TProduct): void {
    const productUUID = element.product.uuid;
    if (element.product.is_in_group === true){
      this.toastrService.warning('За продукът има активна група и не може да се правят промени до изтичането и.');
      return;
    }
    this.productsService.deleteProduct(productUUID).subscribe(() => {
      this.loadTotals();
      if (this.numberPerPageValue !== -1) {
        this.initialLoad();
      }
      this.productsDataSource = this.productsDataSource.filter(product => product.product.uuid !== productUUID);
      this.toastrService.success(Successes.itemDelete[this._language]);
    });
  }

  public onTableScroll(e) {
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit && !this._scrolling && this.numberPerPageValue == -1 && this.page < this._maxPage) {
      this._scrolling = true;
      this.page++;
      this.getProducts();
    }
  }

  public chooseNumberPerPage(event) {
    const value = event.value;
    this.numberPerPageValue = value;
    this.localStorageService.setLocalStorageItem(Labels.lsNumberOfProductsPerPage, value);
    this.page = 1;
    this.productsDataSource = [];
    if (this.numberPerPageValue == -1) {
      for(let i = 0; i < this._initialNumberOfLoadedPages; i++) {
        this.page++;
        this.getProducts();
      }
    }
    else {
      this.getProducts();
    }
  }


  public loadSpecificPage(pageNumber) {
    if (pageNumber < 0 || pageNumber > this._maxPage) {
      return;
    }
    this.productsDataSource = [];
    this.page = pageNumber;
    this.getProducts();
  }

  public getPriceRange(element: TProduct) {
    if (!element.product.pricing.length) {
      return {
        from: '',
        to: ''
      }
    }
    let pricing = element.product.pricing.sort((a, b) => a.quantity_from - b.quantity_from);
    const pricingCounts = element.product.pricing.length;
    return {
      from: pricing[pricingCounts - 1].price_per_piece + Currencies.currencies[this._language],
      to: pricing[0].price_per_piece + Currencies.currencies[this._language]
    }
  }

  public convertDate(element: TProduct) {
    return Convertor.convertDate.convertDateTimeWithDelimiter(element.product.updated_at, '/');
  }

  public convertStatus(element: TProduct) {
    return {
      style: "color: " + ProductStatuses.statuses[element.product.state].color,
      status: ProductStatuses.statuses[element.product.state][this._language]
    }
  }

  public search() {
    this.productsDataSource = [];
    this.initialLoad();
  }


  public setSearchedText(event) {
    const searchQuery = event.target.value;
    this._searchString = searchQuery;
  }

  public getQuantity(quantity) {
    return quantity +  SharedLabels[this._language].quantity;
  }

  public getItemImageURL(UUID: string) {
    const imageIfExists = this.productImages.images.find(image => image.itemUUID == UUID);
    return (imageIfExists || imageIfExists.imageUrl) ? imageIfExists.imageUrl : '';
  }

  public getOptionsForSku(SKUUUID: string) : Option[] {
    let options: Option[] = [];
    this.skuOptions.tableSkus.forEach(tablesku => {
      if (tablesku.sku == SKUUUID) {
        options.push(tablesku.skuOption);
      }
    })
    return options;
  }

  public changeStateFilter(state: string) {
    this.state = state;
    this.initialLoad();
  }

  public changeState(element: TProduct, state: string): void {
    if (element.product.is_in_group === true){
      this.toastrService.warning('За продукът има активна група и не може да се правят промени до изтичането и.');
      return;
    }
    this.productsService.changeProductState(element.product.uuid, state).subscribe(() => {
      this.productsDataSource = this.productsDataSource.filter(product => product.product.uuid !== element.product.uuid);
      this.toastrService.success(Successes.itemStateChange[this._language]);
      this.loadTotals();
    });
  }

  public edit(element: TProduct): void {
    if (element.product.is_in_group === true){
      this.toastrService.warning('За продукът има активна група и не може да се правят промени до изтичането и.');
      return;
    }
    const params = RequestHelper.addRESTParams([
      'dashboard', 'products', 'add', element.product.uuid
    ]);
    this.router.navigate([params]);
  }

  public goToPreviousPage() {
    if (this.page == 1) {
      return;
    }
    this.page--;
    this.productsDataSource = [];
    this.getProducts();
  }

  public goToNextPage() {
    if (this.page == this._maxPage) {
      return;
    }
    this.page++;
    this.productsDataSource = [];
    this.getProducts();

  }

  public resetPage() {
    this.page = 1;
    this.getProducts();
  }
}
