import { Component, OnInit } from '@angular/core';
import { TableImageCollection } from 'src/models/DTO/V2/image/TableImageCollection';
import { TableProduct } from 'src/models/DTO/V2/product/TableProduct';
import { ProductCollection } from 'src/models/DTO/V2/product/ProductCollection';
import { TableSkuOptionCollection } from 'src/models/DTO/V2/skuOptions/TableSkuOptionCollection';
import { ProductsService } from 'src/services/requestServices/V2/products/products.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { STRIPE } from 'src/config/labels/stripe';
import { Option } from 'src/models/DTO/V2/skuOptions/Option';
import { Currencies } from 'src/config/labels/currencies';
import { ProductStatuses } from 'src/config/labels/productStatuses';
import { Convertor } from 'src/infrastructure/convertorHelpers/convertor';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { PartnerInfo } from 'src/models/DTO/V2/info/PartnerInfo';
import { OrganisationInfo } from 'src/models/DTO/V2/info/OrganisationInfo';
import { trigger, state, style, transition, animate } from '@angular/animations';



@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OverviewComponent implements OnInit {

  private _pageNumber = 1;
  private _initialNumberOfLoadedPages : number = 3;
  private _numberPerPageValue = 20;
  private _maxPage : number = 100;
  private _state = "active"
  private _scrolling = false;

  public displayedColumnsForProductTable: string[];
  public productsDataSource: TableProduct[] = [];
  public  productImages: TableImageCollection = {
    images: []
  };
  public skuOptions: TableSkuOptionCollection = {
    tableSkus: []
  };
  public expandedElement: TableProduct | null;
  private _partnerInfo: PartnerInfo;


  constructor(
    private productsService: ProductsService,
    private imageService: ImageService,
    private infoService: InfoService
  ) {
    this.infoService.info().subscribe(info => {
      this._partnerInfo = info;
    });
    for (let index = 0; index < this._initialNumberOfLoadedPages; index++) {
      this.loadProductsForTable();
      this._pageNumber++;

    }
    this.displayedColumnsForProductTable = ['image', 'name', 'quantity', 'meduimPrice', 'date', 'status'];
  }

  ngOnInit(): void {
  }

  private loadProductsForTable() {
    this.productsService.getProducts(this._numberPerPageValue, this._pageNumber, this._state).subscribe((productsData) => {
      this.fillProductsTable(productsData);
    });
  }

  private fillProductsTable(productsData: ProductCollection): void {
    this._maxPage = productsData.meta.last_page;
    productsData.data.forEach(product => {
        if (!this.productsDataSource.find((currentProduct => currentProduct.product.uuid == product.uuid))) {
          const newTableProductCollectionItem: TableProduct = {
            product: {
              uuid: product.uuid,
              name: product.name,
              state: product.state,
              pricing: [],
              organisation: {
                  uuid: product.organisation.uuid,
                  native_name: product.organisation.native_name,
                  name_in_latin: product.organisation.name_in_latin
              },
              category: {
                  uuid: product.category.uuid,
                  name: product.category.name
              },
              brand: {
                  uuid: product.brand.uuid,
                  name: product.brand.name
              },
              skus: [],
              total_available_quantity: product.total_available_quantity,
              total_reserved_quantity: product.total_reserved_quantity,
              created_at: product.created_at,
              updated_at: product.updated_at,
              is_in_group: product.is_in_group
            }
          };
          product.pricing.forEach(price => {
            newTableProductCollectionItem.product.pricing.push({
              uuid: price.uuid,
              quantity_from: price.quantity_from,
              quantity_to: price.quantity_to,
              price_per_piece: price.price_per_piece,
              diff_in_percent_from_single_quantity: price.diff_in_percent_from_single_quantity
            });
          });
          product.skus.forEach(sku => {
            newTableProductCollectionItem.product.skus.push({
              uuid: sku.uuid,
              sku: sku.sku,
              available_quantity: sku.available_quantity,
              reserved_quantity: sku.reserved_quantity,
              options: sku.options
            });
          });
          this.productsDataSource  = [...this.productsDataSource, newTableProductCollectionItem]
        }
        this._scrolling = false;
      });
    this.getImagesForItems(this.productsDataSource);
  }

  private getImagesForItems(productCollection: TableProduct[]) {
    this.productImages.images = [];
    productCollection.forEach(product => {
      this.imageService.getImagesPerProduct("PRODUCT", product.product.uuid).subscribe(images => {
        this.productImages.images.push({
          itemUUID:  product.product.uuid,
          imageUrl: images.data[0].original.url
        })
      });
      product.product.skus.forEach(sku => {
        this.imageService.getImagesPerProduct("SKU", sku.uuid).subscribe(images => {
          this.productImages.images.push({
            itemUUID: sku.uuid,
            imageUrl: images.data[0].original.url
          })
        });
      })
    })
  }

  public onTableScroll(e) {
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit && !this._scrolling && this._pageNumber < this._maxPage) {
      this._scrolling = true;
      this._pageNumber++;
      this.loadProductsForTable();
    }
  }

  get lables() {
    return STRIPE;
  }

  get organisation() : OrganisationInfo | null {
    return this._partnerInfo?.data?.organisations[0];
  }

  public getItemImageURL(UUID: string) {
    const imageIfExists = this.productImages.images.find(image => image.itemUUID == UUID);
    return (imageIfExists) ? imageIfExists.imageUrl : '';
  }

  public getOptionsForSku(SKUUUID: string) : Option[] {
    let options: Option[] = [];
    this.skuOptions.tableSkus.forEach(tablesku => {
      if (tablesku.sku == SKUUUID) {
        options.push(tablesku.skuOption);
      }
    })
    return options;
  }

  public getPriceRange(element: TableProduct) {
    const pricingCounts = element.product.pricing.length;
    return element.product.pricing[pricingCounts - 1].price_per_piece + Currencies.currencies.en + ' - ' +
    element.product.pricing[0].price_per_piece + Currencies.currencies.en
  }

  public convertDate(element: TableProduct) {
    return Convertor.convertDate.convertDateTimeWithDelimiter(element.product.updated_at, '/');
  }

  public convertStatus(element: TableProduct) {
    return ProductStatuses.statuses[element.product.state].en;
  }
}
