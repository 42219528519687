export const ErrorTranslations = {
    translate: function(stringToTranslate : string, languageToTranslate : string) : string {
        let stringToReturn = '';
        ErrorTranslations.translations.forEach(translation => {
            if (stringToTranslate == translation.original && translation[languageToTranslate]) {
                stringToReturn = translation[languageToTranslate];
            }
        })
        return stringToReturn || stringToTranslate;
    },

    translations: [
        {
            original: 'The password field is required.',
            bg: 'Паролата е задължителна.'
        },
        {
            original: 'The password is incorrect.',
            bg: 'Паролата ви е грешна.'
        },
        {
            original: 'The sku field is reqiured.',
            bg: 'Въведе SKU (код на продукта)'
        },
        {
            original: 'The quantity field is reqiured.',
            bg: 'Въведете количество за дадената разновидност.'
        },
        {
            original: 'The selected email is invalid.',
            bg: 'Въвели сте грешен имейл за вход.'
        },
        {
            original: 'The email must be a valid email address.',
            bg: 'Въвели сте грешен формат на имейл.'
        },
        {
            original: 'The quantity to field is invalid.',
            bg: 'Въведете поле "До  количество"'
        },
        {
            original: 'The quantity from field is invalid',
            bg: 'Въведете поле "От количество"'
        },
        {
            original: 'The prices.0.price field is required.',
            bg: 'Въведете цена за 1 брой'
        },
        {
            original: 'The prices.1.price field is required.',
            bg: 'Въведете цена за 2 броя'
        },
        {
            original: 'The prices.2.price field is required.',
            bg: 'Въведете цена за 3 броя'
        },
        {
            original: 'The prices.3.price field is required.',
            bg: 'Въведете цена за 4 броя'
        },
        {
            original: 'The SKU already exists.',
            bg: 'Съществува продукт със същото SKU/код на продукт' 
        },
        {
            original: 'The category uuid field is required.',
            bg: 'Изберете категория на продукта'
        },
        {
            original: 'The brand uuid field is required.',
            bg: 'Изберете марка на продукта'
        },
        {
            original: 'The name field is required.',
            bg: 'Въведете наименувание на продукта'
        },
        {
            original: 'The sku field is required.',
            bg: 'Въведете SKU/код на продукта'
        },
        {
            original: 'The ean has already been taken.',
            bg: 'Съществува продукт със същия EAN/Баркод"'
        },
        {
            original: 'The delivery time in days field is required.',
            bg: 'Въведете срок за доставка на продукта.'
        },
        {
            original: 'The dimensions.weight field is required.',
            bg: 'Въведете тегло на продукта.'
        },
        {
            original: 'The city field is required.',
            bg: 'Въведете град.'
        },
        {
            original: 'The street name field is required.',
            bg: 'Въведете улица.'
        },
        {
            original: 'The street number field is required.',
            bg: 'Въведете номер на улицата.'
        },
        {
            original: 'The mobile phone 1 field is required.',
            bg: 'Въведете телефонен номер.'
        },
        {
            original: 'The mobile phone 1 field contains an invalid number.',
            bg: 'Невалидна стойност за телефонен номер.'
        },
        {
            original: 'The courier services.other options.type field is not a valid Domain\\ProductsPackaging\\Enums\\ProductPackagingCourierTypeEnum.',
            bg: 'Въведете вид куриерска услуга.'
        },
        {
            original: 'The pickup hour field is requiered when dispatch from is organisation_office.',
            bg: 'Въведете час за вземане на пратки.'
        },
        {
            original: 'The pickup hour must be a date after or equal to 09:00.',
            bg: 'Въведете час между 09:00 и 18:00'
        },
        {
            original: 'The pickup hour must be a date before or equal to 18:00.',
            bg: 'Въведете час между 09:00 и 18:00'
        },
        {
            original: "You cannot cancel this PaymentIntent because it has a status of canceled. Only a PaymentIntent with one of the following statuses may be canceled: requires_payment_method, requires_capture, requires_confirmation, requires_action, processing.",
            bg: "Поръчка с отказано плащане не може да бъде отказана"
        },
        {
            original: "You can`t pick up the shipment because it has not been printed yet",
            bg: "Изтеглете товарителницата, преди да я заявите за вземане от куриер"
        }
    ]
}


